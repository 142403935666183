import type { NextPage } from 'next';
import React from 'react';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useToggle } from '@react-hookz/web';
import axios from 'axios';
import { useRouter } from 'next/router';

import { SideBar } from 'src/components/SideBar';
import FullScreenLoader, { Loader } from 'src/components/Loader';
import { useAuth } from 'src/util/use-auth';
import { useAPI } from 'src/util/use-api';

const Login: NextPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('login');
  const { login, originalRequestUrl } = useAuth();
  const { baseApiUrl } = useAPI();

  // We'll manage two steps:
  //   step 1 -> username only
  //   step 2 -> password
  const [step, setStep] = React.useState<number>(1);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isFullScreenLoading, setFullScreenLoading] = useToggle(true);
  const [errorDetail, setErrorDetail] = React.useState<any>(null);
  const router = useRouter();

  // Track whether we've done the domain-level SSO check
  // so it doesn't happen more than once in development (Strict Mode).
  const [didCheckDomainSSO, setDidCheckDomainSSO] = React.useState(false);

  // Helper to handle a returned URL that may be relative
  const handleRedirect = (url: string) => {
    // If the URL already starts with http or https, assume it's absolute
    if (/^https?:\/\//i.test(url)) {
      window.location.href = url;
    } else {
      // Otherwise, treat it as relative
      window.location.href = process.env.NEXT_PUBLIC_API_URL + url;
    }
  };

  /**
   * Performs a domain-level SSO check via GET /sso/url_check/?url=<domain>.
   * If the call returns 200 with { url: ... }, it means there's an SSO
   * for the current domain. If 404, there's none. Other errors are handled.
   */
  const performDomainCheck = React.useCallback(async () => {
    const currentDomain = window.location.origin;

    // For localhost or 127.0.0.1 and production disable...
    if (
      (currentDomain.includes('localhost') ||
        currentDomain.includes('127.0.0.1')) &&
      baseApiUrl.includes('https://api.businessradar.com')
    ) {
      setFullScreenLoading(false);
      return;
    }

    try {
      const nextUrl = window.location.origin + originalRequestUrl; // or a specific route
      const response = await axios.get(
        `${
          process.env.NEXT_PUBLIC_API_URL
        }/sso/url_check/?url=${encodeURIComponent(
          currentDomain
        )}&next_url=${encodeURIComponent(nextUrl)}`
      );
      if (response.data?.url) {
        // For testing, we won't redirect immediately:
        handleRedirect(response.data.url);
        // console.log('Domain SSO found. Would redirect to:', response.data.url);
      } else {
        console.log('No domain-level SSO found.');
        setFullScreenLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        // No domain-level SSO => do nothing
        console.log('No domain-level SSO (404).');
        setFullScreenLoading(false);
      } else {
        // Another error
        setErrorDetail({
          title: t('Error'),
          description:
            error?.response?.data?.description ||
            error?.message ||
            'Unknown error during domain check.',
        });
        setFullScreenLoading(false);
      }
    }
  }, [baseApiUrl, originalRequestUrl, setFullScreenLoading, t]);

  /**
   * Performs a username-level SSO check via GET /sso/username_check/?username=<name>&next_url=<...>.
   * If the call returns 200 with { url: ... }, it means that user is SSO-managed.
   * If 404, it's a normal user → proceed to password step.
   */
  const performUsernameCheck = React.useCallback(
    async (username: string) => {
      const currentDomain = window.location.origin;
      setIsLoading(true);

      // For localhost or 127.0.0.1 and production disable...
      if (
        (currentDomain.includes('localhost') ||
          currentDomain.includes('127.0.0.1')) &&
        baseApiUrl.includes('https://api.businessradar.com')
      ) {
        setIsLoading(false);
        setStep(2);
        return;
      }

      try {
        const nextUrl = window.location.origin + originalRequestUrl; // or a specific route
        const response = await axios.get(
          `${
            process.env.NEXT_PUBLIC_API_URL
          }/sso/username_check/?username=${encodeURIComponent(
            username
          )}&next_url=${nextUrl}`
        );

        // If we get a 200 and response.data.url, redirect there
        if (response.data?.url) {
          handleRedirect(response.data.url);
          return;
        }
        setIsLoading(false);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          // No SSO for that username => show password step
          setStep(2);
          setIsLoading(false);
        } else {
          // Another error
          setErrorDetail({
            title: t('Error'),
            description:
              error?.response?.data?.description ||
              error.message ||
              'Unknown error',
          });
          setIsLoading(false);
        }
      }
    },
    [baseApiUrl, originalRequestUrl, setIsLoading, t]
  );

  // Perform the domain-level check once on mount (if not already done).
  React.useEffect(() => {
    if (!didCheckDomainSSO && typeof window !== 'undefined') {
      console.log('Checking domain');
      setDidCheckDomainSSO(true);
      void performDomainCheck();
    }
  }, [didCheckDomainSSO, performDomainCheck, setFullScreenLoading]);

  /**
   * onFinish is triggered when user submits the form. We'll handle
   * two-step logic here.
   */
  const onFinish = async (values: any) => {
    setErrorDetail(null);

    if (step === 1) {
      // Step 1: We only have username. We need to check if this user
      // is managed by SSO or not.
      await performUsernameCheck(values.username);
    } else {
      // Step 2: We have username + password. Proceed with normal login.
      setIsLoading(true);
      login(values.username, values.password)
        .then(() => {
          setErrorDetail(null);
          setIsLoading(false);
          router.push(originalRequestUrl || '/');
        })
        .catch((error: any) => {
          setIsLoading(false);
          if (error.response?.data?.field_errors) {
            form.setFields(
              Object.keys(error.response.data.field_errors).map(
                (fieldName: string) => ({
                  name: fieldName,
                  value: values[fieldName],
                  errors: [error.response.data.field_errors[fieldName]],
                })
              )
            );
          }

          if (error.response?.data?.description) {
            if (error.response.data.code === 'authentication_failed') {
              setErrorDetail({
                title: t('Authentication Failed'),
                description: t(
                  'Account not found or password incorrect, request a new password or contact Technical support (support@businessradar.com)'
                ),
              });
            } else {
              setErrorDetail({
                title: error.response.data.title,
                description: error.response.data.description,
              });
            }
          } else {
            // Fallback error
            setErrorDetail({
              title: t('Error'),
              description: error.message || 'Unknown error',
            });
          }
        });
    }
  };

  if (isFullScreenLoading) {
    return <FullScreenLoader />;
  }

  return (
    <SideBar
      title={t('Login')}
      description={
        <ul>
          <li>
            <a href="/account/password-reset" style={{ color: '#fff' }}>
              {t('Password Forgotten')}
            </a>
          </li>
        </ul>
      }
    >
      <Loader loading={isLoading}>
        {errorDetail && errorDetail.title && errorDetail.description ? (
          <Alert
            message={errorDetail.title}
            description={errorDetail.description}
            className="gx-mb-4"
            type="error"
            showIcon
          />
        ) : null}

        <Form
          form={form}
          name="loginForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className="gx-signin-form gx-form-row0"
        >
          {/* Username is always visible */}
          <Form.Item
            label={t('Username')}
            name="username"
            data-cy="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          {step === 2 && (
            <>
              {/* Show password only if step === 2 */}
              <Form.Item
                label={t('Password')}
                name="password"
                data-cy="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
                data-cy="remember"
              >
                <Checkbox>{t('Remember Me')}</Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              data-cy="login-button"
              className="gx-mb-0"
              htmlType="submit"
            >
              {step === 1 ? t('Next') : t('Submit')}
            </Button>
          </Form.Item>
        </Form>
      </Loader>
    </SideBar>
  );
};

// @ts-ignore
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'login'])),
  },
});

export default Login;
